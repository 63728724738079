<template>
  <CBox>
    <CBox
      bg="white"
      minH="100px"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CBox py="20px" mx="20px" justifyContent="space-between" display="flex">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="15px"
          fontWeight="600"
          color="fontMain"
        >
          Filters
        </CText>
        <!-- <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="15px"
          fontWeight="600"
          color="activeMenu"
          class="pointer"
          @click.native="$router.push({ name: 'Hashtags', params: { id } })"
        >
          Lihat Semua
        </CText> -->
      </CBox>
      <CDivider mt="0" />
      <CBox display="flex" justifyContent="center" v-if="loading">
        <CSpinner />
      </CBox>
      <CBox v-if="$route.name === 'Leaderboard'">
        <CText
          py="4px"
          mx="20px"
          mb="2"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          fontWeight="600"
          color="fontMain"
        >
          General
        </CText>
        <CBox
          v-for="filter in timeFilters"
          :key="filter.value"
          py="4px"
          mx="20px"
          mb="2"
          ml="36px"
        >
          <CText
            v-if="filter.value === 'all'"
            class="pointer"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            :fontWeight="
              filter.value === currentLeaderboardsActiveGroup ? 600 : 400
            "
            color="activeMenu"
            @click.native="
              $route.params.track
                ? $router.push({
                    name: 'Track Leaderboard',
                    params: {
                      track: $route.params.track,
                      id,
                    },
                    query: {
                      group: filter.value,
                    },
                  })
                : $router.push({
                    name: 'Leaderboard',
                    params: {
                      slug: id,
                    },
                    query: {
                      group: filter.value,
                    },
                  })
            "
          >
            {{ filter.name }}
          </CText>
          <CText
            v-else
            class="pointer"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            :fontWeight="
              filter.value === currentLeaderboardsActiveGroup ? 600 : 400
            "
            color="activeMenu"
            @click.native="
              $route.params.track
                ? $router.push({
                    name: 'Track Leaderboard',
                    params: {
                      track: $route.params.track,
                      id,
                      type: currentLeaderboardsActiveFilters.type,
                      type_id: currentLeaderboardsActiveFilters.id,
                    },
                    query:
                      currentLeaderboardsActiveFilters.type === 'main'
                        ? { group: filter.value }
                        : {
                            [`${currentLeaderboardsActiveFilters.type}`]:
                              currentLeaderboardsActiveFilters.name,
                            group: filter.value,
                          },
                  })
                : $router.push({
                    name: 'Leaderboard',
                    params: {
                      slug: id,
                      type: currentLeaderboardsActiveFilters.type,
                      type_id: currentLeaderboardsActiveFilters.id,
                    },
                    query:
                      currentLeaderboardsActiveFilters.type === 'main'
                        ? { group: filter.value }
                        : {
                            [`${currentLeaderboardsActiveFilters.type}`]:
                              currentLeaderboardsActiveFilters.name,
                            group: filter.value,
                          },
                  })
            "
          >
            {{ truncateText(filter.name, 20) }}
          </CText>
        </CBox>
        <CDivider />
      </CBox>
      <CBox v-for="(label, i) in filterLabels" :key="i">
        <CText
          v-if="
            displayedFilters[label.toLowerCase()] &&
            displayedFilters[label.toLowerCase()].length !== 0
          "
          py="4px"
          mx="20px"
          mb="2"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          fontWeight="600"
          color="fontMain"
          text-transform="capitalize"
        >
          {{ label }}
        </CText>
        <CText
          v-if="label === 'Province'"
          py="4px"
          ml="36px"
          mb="2"
          class="pointer"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          :fontWeight="
            displayedFilters[label.toLowerCase()].name ===
            currentLeaderboardsActiveFilters.name
              ? 600
              : 400
          "
          color="activeMenu"
          @click.native="
            $route.params.track
              ? $router.push({
                  name: 'Track Leaderboard',
                  params: {
                    track: $route.params.track,
                    id,
                    type: setFilterType(label.toLowerCase()),
                    type_id: displayedFilters[label.toLowerCase()].id,
                  },
                  query: {
                    [`${setFilterType(label.toLowerCase())}`]:
                      displayedFilters[label.toLowerCase()].name,
                    group: currentLeaderboardsActiveGroup,
                  },
                })
              : $router.push({
                  name: 'Leaderboard',
                  params: {
                    slug: id,
                    type: setFilterType(label.toLowerCase()),
                    type_id: displayedFilters[label.toLowerCase()].id,
                  },
                  query: {
                    [`${setFilterType(label.toLowerCase())}`]:
                      displayedFilters[label.toLowerCase()].name,
                    group: currentLeaderboardsActiveGroup,
                  },
                })
          "
        >
          {{ truncateText(displayedFilters[label.toLowerCase()].name, 20) }}
        </CText>
        <CText
          v-for="(filter, idx) in displayedFilters[label.toLowerCase()]"
          py="4px"
          ml="36px"
          mb="2"
          class="pointer"
          :key="idx"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          :fontWeight="
            filter.slug === currentLeaderboardsActiveFilters.id ||
            filter.id === currentLeaderboardsActiveFilters.id
              ? 600
              : 400
          "
          color="activeMenu"
          @click.native="
            $route.params.track
              ? $router.push({
                  name: 'Track Leaderboard',
                  params: {
                    track: $route.params.track,
                    slug: id,
                    type: setFilterType(label.toLowerCase()),
                    type_id:
                      label === 'Hashtags' ||
                      label === 'Arena' ||
                      label === 'Language'
                        ? filter.slug
                        : filter.id,
                    name: filter.name,
                  },
                  query: {
                    [`${setFilterType(label.toLowerCase())}`]: filter.slug,
                    group: currentLeaderboardsActiveGroup,
                  },
                })
              : $router.push({
                  name: 'Leaderboard',
                  params: {
                    slug: id,
                    type: setFilterType(label.toLowerCase()),
                    type_id:
                      label === 'Hashtags' ||
                      label === 'Arena' ||
                      label === 'Language'
                        ? filter.slug
                        : filter.id,
                    name: filter.name,
                  },
                  query: {
                    [`${setFilterType(label.toLowerCase())}`]: filter.slug,
                    group: currentLeaderboardsActiveGroup,
                  },
                })
          "
        >
          {{ truncateText(filter.name, 20) }}
        </CText>
        <CDivider
          v-if="
            displayedFilters[label.toLowerCase()] &&
            displayedFilters[label.toLowerCase()].length !== 0
          "
        />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { GET_LEADERBOARDS_FILTERS } from "@/store/leaderboard-new.module";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      displayedFilters: {},
      filterLabels: [],
      timeFilters: [
        { name: "Show All", value: "all" },
        { name: "This Year", value: "yearly" },
        { name: "This Month", value: "monthly" },
      ],
    };
  },
  watch: {
    id() {
      this.getData();
    },
    currentLeaderboardsFilters(val) {
      this.getFilterLabels(val);
      this.getFilterDatas(val);
    },
  },
  computed: {
    currentLeaderboards() {
      return this.$store.getters.currentNewLeaderboards;
    },
    currentLeaderboardsFilters() {
      return this.$store.getters.currentNewLeaderboardsFilters;
    },
    currentLeaderboardsActiveFilters() {
      return this.$store.getters.currentNewLeaderboardsActiveFilters;
    },
    currentLeaderboardsActiveGroup() {
      return this.$store.getters.currentNewLeaderboardsActiveGroup;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    setFilterType(type) {
      switch (type) {
        case "province":
          return "province";
        case "schools":
          return "school";
        case "hashtags":
          return "hashtag";
        case "arena":
          return "hashtag";
        case "language":
          return "hashtag";
        case "club":
          return "club";
        case "official club":
          return "club";
        default:
          return "main";
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getFilterDatas(data) {
      this.displayedFilters = data;
    },
    getFilterLabels(filters) {
      const labels = Object.keys(filters);
      if (labels.length !== 0) {
        labels.map((label) => {
          if (!this.filterLabels.includes(this.capitalizeFirstLetter(label)))
            this.filterLabels.push(this.capitalizeFirstLetter(label));
        });
      }
    },
    async getData() {
      try {
        if (this.id) {
          await this.$store.dispatch(GET_LEADERBOARDS_FILTERS, {
            id: this.id,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
