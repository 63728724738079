<template>
  <CBox>
    <Loading :active="isLoadingLeaderboard" />
    <CBox mt="30px">
      <BackHeader />
      <CGrid
        :templateColumns="['12fr', '12fr', '7.5fr 2.5fr', '7.5fr 2.5fr']"
        :gap="[3, 3, 6, 6]"
      >
        <CBox min-w="100px" w="100%" max-w="100%">
          <AppDescription
            :currentAppType="currentAppType"
            description="Leaderboard Devcode"
            :showStats="true"
            :stats="currentLeaderboardsOverview"
            :showTags="true"
          />
          <CBox
            bg="white"
            :mt="['13px', '13px', '30px', '30px']"
            minH="100px"
            borderRadius="5px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            overflow="auto"
          >
            <CBox h="10px" mx="20px">
              <!-- <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
                >Your Submission History</CText
              > -->
            </CBox>
            <CBox px="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="700"
                >{{ changeTableHeaderTitle(currentLeaderboardsActiveGroup) }}
                Leaderboards
                <span v-if="currentLeaderboardsActiveFilters.name">{{
                  `- ${currentLeaderboardsActiveFilters.name}`
                }}</span></CText
              >
              <CText
                v-if="
                  currentLeaderboardsActiveGroup === 'yearly' ||
                  currentLeaderboardsActiveGroup === 'monthly'
                "
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="12px"
                fontWeight="400"
                >will be reset after
                <strong>{{
                  formatLastDayDate(currentLeaderboards.meta.lastDay)
                }}</strong></CText
              >
            </CBox>
            <CDivider />
            <CFlex px="20px" alignItems="center">
              <CBox overflow="auto" d="flex" alignItems="center" py="12px">
                <CBox
                  flex="0 0 auto"
                  d="flex"
                  alignItems="center"
                  px="12px"
                  py="2px"
                  borderRadius="5px"
                  fontSize="14px"
                  border="1px solid"
                  :borderColor="!$route.query.club ? 'activeMenu' : 'bgMain'"
                  :fontWeight="defaultMetricsLabel === -1 ? '' : '400'"
                  cursor="pointer"
                  @click.native="
                    $route.params.track
                      ? $router.push({
                          name: 'Track Leaderboard',
                          params: {
                            track: $route.params.track,
                            id: parseInt(currentAppType.id),
                            type: 'main',
                          },
                          query: {
                            ['group']: 'all',
                            group: currentLeaderboardsActiveGroup,
                          },
                        })
                      : $router.push({
                          name: 'Leaderboard',
                          params: {
                            slug: parseInt(currentAppType.id),
                            type: 'main',
                          },
                          query: {
                            ['group']: 'all',
                            group: currentLeaderboardsActiveGroup,
                          },
                        })
                  "
                  mr="6px"
                  ><CText
                    :color="!$route.query.club ? 'activeMenu' : 'fontMain'"
                    >All</CText
                  >
                </CBox>
                <CBox
                  v-for="(v, i) in currentLeaderboardsFilters"
                  :key="i"
                  flex="0 0 auto"
                  d="flex"
                  alignItems="center"
                  px="12px"
                  py="2px"
                  borderRadius="5px"
                  border="1px solid"
                  :borderColor="
                    $route.query.club === v.slug ? 'activeMenu' : 'bgMain'
                  "
                  :fontWeight="defaultMetricsLabel === -1 ? '' : '400'"
                  cursor="pointer"
                  fontSize="14px"
                  @click.native="
                    $route.params.track
                      ? $router.push({
                          name: 'Track Leaderboard',
                          params: {
                            track: $route.params.track,
                            id: parseInt(currentAppType.id),
                            type: 'club',
                            type_id: v.id,
                          },
                          query: {
                            ['club']: v.slug,
                            group: currentLeaderboardsActiveGroup,
                          },
                        })
                      : $router.push({
                          name: 'Leaderboard',
                          params: {
                            slug: parseInt(currentAppType.id),
                            type: 'club',
                            type_id: v.id,
                          },
                          query: {
                            ['club']: v.slug,
                            group: currentLeaderboardsActiveGroup,
                          },
                        })
                  "
                  mr="6px"
                  ><CText
                    :color="
                      $route.query.club === v.slug ? 'activeMenu' : 'fontMain'
                    "
                    >{{ truncateText(v.name, 15) }}</CText
                  >
                </CBox>
                <CBox v-if="currentAppType.type === 'BE'">
                  <CBox
                    flex="0 0 auto"
                    maxW="150px"
                    h="40px"
                    d="flex"
                    alignItems="center"
                    mb="12px"
                    px="6px"
                    borderRadius="12px"
                    border="1px solid"
                    :borderColor="
                      defaultMetricsLabel === -1 ? 'activeMenu' : 'bgMain'
                    "
                    :fontWeight="defaultMetricsLabel === -1 ? 'bold' : '400'"
                    cursor="pointer"
                    @click.native="changeLabelMetrics(-1, 'Default Result')"
                    ><CText
                      :color="
                        defaultMetricsLabel === -1 ? 'activeMenu' : 'fontMain'
                      "
                      >Default Result</CText
                    >
                  </CBox>
                  <CBox
                    v-for="(metric, i) of currentTestMetrics"
                    :key="i"
                    flex="0 0 auto"
                    minW="150px"
                    h="40px"
                    d="flex"
                    alignItems="center"
                    mb="12px"
                    px="6px"
                    borderRadius="12px"
                    border="1px solid"
                    style="font-size: 14px"
                    :borderColor="
                      defaultMetricsLabel === i ? 'activeMenu' : 'bgMain'
                    "
                    :fontWeight="defaultMetricsLabel === i ? 'bold' : '400'"
                    cursor="pointer"
                    @click.native="changeLabelMetrics(i, metric.label)"
                    ><CText
                      :color="
                        defaultMetricsLabel === i ? 'activeMenu' : 'fontMain'
                      "
                      >{{ metric.label }}</CText
                    >
                  </CBox>
                </CBox>
              </CBox>
              <CBox :w="['auto', '20%', '20%', '20%']" ms="auto">
                <CSelect
                  v-model="defaultRankType"
                  variant="none"
                  borderRadius="md"
                  d="flex"
                  ml="auto"
                  w="full"
                  min-w="120px"
                  py="4px"
                  size="0"
                  fontSize="14px"
                  textAlign="center"
                  bg="#EFF1F5"
                  style="font-size: 14px"
                  @change="changeRankType(defaultRankType)"
                >
                  <option value="performance">Duration</option>
                  <option value="first-success">Finish Time</option>
                </CSelect>
              </CBox>
            </CFlex>
            <CBox v-if="defaultMetricsLabel === -1 && !statistic">
              <CBox
                bg="bgSocialBadge"
                h="37px"
                :w="['170vw', '170vw', 'auto', 'auto']"
              >
                <CGrid
                  templateColumns="1fr 5fr 2fr 1fr 2fr"
                  :gap="2"
                  h="inherit"
                >
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >RANK</CText
                    >
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >USER</CText
                    >
                  </CBox>
                  <CBox
                    v-if="
                      ['LOGIC', 'PLAYGROUND'].includes(currentAppType.type) &&
                      defaultRankType == 'first-success'
                    "
                    align="center"
                    py="auto"
                    my="auto"
                  >
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >Finished Date</CText
                    >
                  </CBox>
                  <CBox
                    v-if="
                      !(
                        defaultRankType == 'first-success' &&
                        ['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)
                      )
                    "
                    align="center"
                    py="auto"
                    my="auto"
                  >
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >{{
                        ["LOGIC", "PLAYGROUND"].includes(currentAppType.type)
                          ? "DURATION"
                          : "APP RESPONSE"
                      }}</CText
                    >
                  </CBox>
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >SCORE</CText
                    >
                  </CBox>
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >ACTION</CText
                    >
                  </CBox>
                </CGrid>
              </CBox>
              <CBox
                v-if="currentLeaderboardsList.length === 0"
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CText
                  mt="24px"
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="500"
                  color="fontMain"
                >
                  {{
                    isLoadingLeaderboard ? "Loading data..." : "No data found"
                  }}
                </CText>
              </CBox>
              <CBox v-else-if="currentLeaderboardsList.length !== 0">
                <CBox
                  h="50px"
                  v-for="(v, i) in currentLeaderboardsList"
                  :key="i"
                  :w="['170vw', '170vw', 'auto', 'auto']"
                  :my="['0.5em', '0.5em', '0', '0']"
                >
                  <CGrid
                    templateColumns="1fr 5fr 2fr 1fr 2fr"
                    :gap="2"
                    h="inherit"
                  >
                    <CBox align="center" py="auto" my="auto">
                      <CImage
                        v-if="i == 0"
                        :src="require('@/assets/icon/ic-first-medal.png')"
                      />
                      <CImage
                        v-else-if="i == 1"
                        :src="require('@/assets/icon/ic-second-medal.png')"
                      />
                      <CImage
                        v-else-if="i == 2"
                        :src="require('@/assets/icon/ic-third-medal.png')"
                      />
                      <CText
                        v-else
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="fontMain"
                        >#{{ i + 1 }}</CText
                      >
                    </CBox>
                    <CBox py="auto" my="auto">
                      <CBox d="block">
                        <CBox d="inline-flex">
                          <CText
                            fontFamily="Montserrat"
                            fontStyle="normal"
                            fontSize="14px"
                            fontWeight="400"
                            >{{ v.full_name }}</CText
                          >
                        </CBox>
                      </CBox>
                      <CBox display="inline-flex" v-if="v.hashtags.length > 0">
                        <CText
                          v-for="(item, k) in v.hashtags"
                          :key="k"
                          fontFamily="Montserrat"
                          fontSize="11px"
                          fontWeight="500"
                          color="activeMenu"
                          @click.native="
                            $route.params.track
                              ? $router.push({
                                  name: 'Track Leaderboard',
                                  params: {
                                    track: $route.params.track,
                                    id: parseInt($route.params.id),
                                    type: 'hashtag',
                                    type_id: item.slug,
                                  },
                                  query: {
                                    hashtag: item.name,
                                    group: currentLeaderboardsActiveGroup,
                                  },
                                })
                              : $router.push({
                                  name: 'Leaderboard',
                                  params: {
                                    id: parseInt($route.params.id),
                                    type: 'hashtag',
                                    type_id: item.slug,
                                  },
                                  query: {
                                    hashtag: item.name,
                                    group: currentLeaderboardsActiveGroup,
                                  },
                                })
                          "
                        >
                          <span v-if="k <= 1">
                            <span class="pointer">#{{ item.name }}</span>
                            <span v-if="k == 1 && v.hashtags.length > 4"
                              >..</span
                            >
                            &nbsp;
                          </span>
                          <b v-if="k == 4">And More</b>
                        </CText>
                      </CBox>
                    </CBox>
                    <CBox
                      v-if="
                        ['LOGIC', 'PLAYGROUND'].includes(currentAppType.type) &&
                        defaultRankType == 'first-success'
                      "
                      align="center"
                      py="auto"
                      my="auto"
                    >
                      <CText
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="12px"
                        fontWeight="400"
                        color="fontMain"
                        >{{ renderFinishedDate(v) }}</CText
                      >
                    </CBox>
                    <CBox
                      v-if="
                        !(
                          defaultRankType == 'first-success' &&
                          ['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)
                        )
                      "
                      align="center"
                      py="auto"
                      my="auto"
                    >
                      <CText
                        v-if="defaultRankType == 'performance'"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="fontMain"
                        >{{ formatDuration(v.duration) }}</CText
                      >
                      <CText
                        v-if="defaultRankType == 'first-success'"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="fontMain"
                        >{{ formatFirstSuccess(v.createdAt) }}</CText
                      >
                    </CBox>
                    <CBox align="center" py="auto" my="auto">
                      <CText
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="fontMain"
                        >{{ v.point }}</CText
                      >
                    </CBox>
                    <CBox align="center" py="auto" my="auto">
                      <CLink
                        as="router-link"
                        :to="
                          $route.params.track
                            ? `/track/${$route.params.track}/job/${v.result_id}`
                            : `/job/${v.result_id}`
                        "
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="activeMenu"
                        class="pointer"
                        >Detail</CLink
                      >
                    </CBox>
                  </CGrid>
                </CBox>
              </CBox>
              <CBox v-if="currentNewUserRank">
                <CBox w="full" m="10px auto" align="center"> ... </CBox>
                <CGrid
                  templateColumns="1fr 5fr 2fr 1fr 2fr"
                  :gap="2"
                  h="inherit"
                >
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="800"
                      color="fontMain"
                      >#{{ currentNewUserRank.rank }}</CText
                    >
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CBox d="block">
                      <CBox d="inline-flex">
                        <CText
                          fontFamily="Montserrat"
                          fontStyle="normal"
                          fontSize="14px"
                          fontWeight="800"
                          >{{ currentNewUserRank.full_name }}</CText
                        >
                      </CBox>
                    </CBox>
                    <CBox
                      display="inline-flex"
                      v-if="currentNewUserRank.hashtags.length > 0"
                    >
                      <CText
                        v-for="(item, k) in currentNewUserRank.hashtags"
                        :key="k"
                        fontFamily="Montserrat"
                        fontSize="11px"
                        fontWeight="500"
                        color="activeMenu"
                        @click.native="
                          $route.params.track
                            ? $router.push({
                                name: 'Track Leaderboard',
                                params: {
                                  track: $route.params.track,
                                  id: parseInt($route.params.id),
                                  type: 'hashtag',
                                  type_id: item.slug,
                                },
                                query: {
                                  hashtag: item.name,
                                  group: currentLeaderboardsActiveGroup,
                                },
                              })
                            : $router.push({
                                name: 'Leaderboard',
                                params: {
                                  id: parseInt($route.params.id),
                                  type: 'hashtag',
                                  type_id: item.slug,
                                },
                                query: {
                                  hashtag: item.name,
                                  group: currentLeaderboardsActiveGroup,
                                },
                              })
                        "
                      >
                        <span v-if="k <= 1">
                          <span class="pointer">#{{ item.name }}</span>
                          <span
                            v-if="
                              k == 1 && currentNewUserRank.hashtags.length > 4
                            "
                            >..</span
                          >
                          &nbsp;
                        </span>
                        <b v-if="k == 4">And More</b>
                      </CText>
                    </CBox>
                  </CBox>
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="600"
                      color="fontMain"
                      >{{ formatDuration(currentNewUserRank.duration) }}</CText
                    >
                  </CBox>
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="800"
                      color="fontMain"
                      >{{ currentNewUserRank.point }}</CText
                    >
                  </CBox>
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      @click.native="
                        $route.params.track
                          ? $router.push({
                              name: 'Track Detail Job',
                              params: {
                                track: $route.params.track,
                                id: currentNewUserRank.result_id,
                              },
                            })
                          : $router.push({
                              name: 'Detail Job',
                              params: {
                                id: currentNewUserRank.result_id,
                              },
                            })
                      "
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="600"
                      color="activeMenu"
                      class="pointer"
                      >Detail</CText
                    >
                  </CBox>
                </CGrid>
              </CBox>
              <CBox
                mt="20px"
                align="center"
                v-if="
                  !isLoadingLeaderboard &&
                  currentLeaderboards.meta.currentPage <
                    currentLeaderboards.meta.maxPage
                "
              >
                <CText
                  v-if="!isLoadingLeaderboard"
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontWeight="500"
                  color="activeMenu"
                  class="pointer"
                  @click.native="nextData()"
                  >Load More ...</CText
                >
                <CSpinner v-else />
              </CBox>
              <CBox h="30px" />
            </CBox>
            <!-- Statistic Display -->
            <CBox v-if="defaultMetricsLabel === -1 && statistic">
              <CBox d="flex" justifyContent="center">
                <VueApexChart type="bar" :series="series" :options="chartOptions" class="c-chart"/>
              </CBox>
            </CBox>
            
            <CBox
              v-if="defaultMetricsLabel !== -1 && currentAppType.type === 'BE'"
            >
              <CBox
                bg="bgSocialBadge"
                h="37px"
                :w="['170vw', '170vw', 'auto', 'auto']"
              >
                <CGrid
                  :templateColumns="
                    currentMetricsLabelNames.length > 3
                      ? '1fr 3fr 1fr 1fr 2fr 2fr'
                      : '1fr 5fr 2fr 1fr 2fr'
                  "
                  :gap="2"
                  h="inherit"
                >
                  <CBox align="center" py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >RANK</CText
                    >
                  </CBox>
                  <CBox py="auto" my="auto">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >USER</CText
                    >
                  </CBox>
                  <CBox
                    v-for="(label, i) in currentMetricsLabelNames"
                    :key="i"
                    align="center"
                    py="auto"
                    my="auto"
                  >
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="11px"
                      fontWeight="bold"
                      color="headerTable"
                      >{{ label.toUpperCase() }}</CText
                    >
                  </CBox>
                </CGrid>
              </CBox>
              <CBox>
                <CBox
                  h="50px"
                  v-for="(v, i) in currentLeaderboardsList"
                  :key="i"
                  :w="['170vw', '170vw', 'auto', 'auto']"
                  :my="['0.5em', '0.5em', '0', '0']"
                >
                  <CGrid
                    :templateColumns="
                      currentMetricsLabelNames.length > 3
                        ? '1fr 3fr 1fr 1fr 2fr 2fr'
                        : '1fr 5fr 2fr 1fr 2fr'
                    "
                    :gap="2"
                    h="inherit"
                  >
                    <CBox align="center" py="auto" my="auto">
                      <CImage
                        v-if="i == 0"
                        :src="require('@/assets/icon/ic-first-medal.png')"
                      />
                      <CImage
                        v-else-if="i == 1"
                        :src="require('@/assets/icon/ic-second-medal.png')"
                      />
                      <CImage
                        v-else-if="i == 2"
                        :src="require('@/assets/icon/ic-third-medal.png')"
                      />
                      <CText
                        v-else
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="fontMain"
                        >#{{ i + 1 }}</CText
                      >
                    </CBox>
                    <CBox
                      py="auto"
                      my="auto"
                      cursor="pointer"
                      @click.native="
                        $router.push({
                          name: 'Profile',
                          params: { emp_id: v.emp_id },
                        })
                      "
                    >
                      <CBox d="block">
                        <CBox d="inline-flex">
                          <CText
                            fontFamily="Montserrat"
                            fontStyle="normal"
                            fontSize="14px"
                            fontWeight="600"
                            color="activeMenu"
                            >{{ v.full_name }}</CText
                          >
                        </CBox>
                      </CBox>
                      <CBox display="inline-flex" v-if="v.hashtags.length > 0">
                        <CText
                          v-for="(tag, k) in v.hashtags"
                          :key="k"
                          fontFamily="Montserrat"
                          fontSize="11px"
                          fontWeight="500"
                          color="activeMenu"
                        >
                          <span v-if="k <= 1">
                            <span class="pointer">#{{ item.name }}</span>
                            <span v-if="k == 1 && v.hashtags.length > 4"
                              >..</span
                            >
                            &nbsp;
                          </span>
                          <b v-if="k == 4">And More</b>
                        </CText>
                      </CBox>
                    </CBox>
                    <CBox
                      v-for="(label, i) in currentMetricsLabelNames"
                      :key="i"
                      align="center"
                      py="auto"
                      my="auto"
                    >
                      <CText
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontSize="14px"
                        fontWeight="600"
                        color="fontMain"
                        >{{
                          checkMetricsDataObject(label, v)
                            ? formatMetrics(
                                v.metrics_value[label][defaultMetricsHeader],
                                label
                              )
                            : "-"
                        }}</CText
                      >
                    </CBox>
                  </CGrid>
                </CBox>
              </CBox>
              <CBox
                mt="20px"
                align="center"
                v-if="
                  !isLoadingLeaderboard &&
                  currentLeaderboards.meta.currentPage <
                    currentLeaderboards.meta.maxPage
                "
              >
                <CText
                  v-if="!isLoadingLeaderboard"
                  fontFamily="Montserrat"
                  fontSize="16px"
                  fontWeight="500"
                  color="activeMenu"
                  class="pointer"
                  @click.native="nextData()"
                  >Load More ...</CText
                >
                <CSpinner v-else />
              </CBox>
              <CBox h="30px" />
            </CBox>
          </CBox>
        </CBox>
        <CBox min-w="100px" w="100%" max-w="100%">
          <LeaderboardFilters :id="parseInt(currentAppType.id)" />
        </CBox>
      </CGrid>
    </CBox>
  </CBox>
</template>

<script>
import AppDescription from "@/components/AppDescription.vue";
import LeaderboardFilters from "@/components/LeaderboardFilters";
import Swal from "sweetalert2";
import { GET_APP_TYPE, GET_APP_METRICS } from "@/store/app-types.module";
import { GET_CLUB_DETAIL } from "@/store/club.module";
import {
  GET_LEADERBOARDS,
  GET_LEADERBOARDS_INITIAL,
  GET_LEADERBOARDS_OVERVIEW,
  SET_LEADERBOARDS_LOADING,
  GET_LEADERBOARDS_FILTERS,
  SET_LEADERBOARDS_ACTIVE_FILTER,
  SET_LEADERBOARDS_ACTIVE_FILTER_GROUP,
  LOADMORE_LEADERBOARDS,
  RESET_LEADERBOARDS,
} from "@/store/leaderboard-new.module";
import config from "@/ConfigProvider";
import Loading from "vue-loading-overlay";
import BackHeader from "@/components/BackHeader";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";
import moment from "moment-timezone";
import VueApexChart from 'vue-apexcharts';

export default {
  metaInfo() {
    return {
      title:
        this.currentAppType && this.currentAppType.name
          ? this.currentAppType.name
          : "Leaderboard",
      titleTemplate: "Devcode - %s",
    };
  },
  components: {
    AppDescription,
    Loading,
    LeaderboardFilters,
    BackHeader,
    VueApexChart
  },
  data() {
    return {
      blogUrl: config.value("DEVCODE_BLOG_URL"),
      page: 1,
      limit: 10,
      defaultMetricsLabel: -1,
      defaultMetricsHeader: "Default Result",
      defaultRankType: "performance",
      defaultTimezone: config.value("DEFAULT_TIMEZONE"),
      refreshInterval: null,
      filterLabels: [],
      statistic: this.$route.query['statistic'],
      series: [{
                name: 'Total User',
                data: [{
                    x: 'Designer',
                    y: 10,
                }, {
                    x: 'Frontend',
                    y:15,
                }, {
                    x: 'Backend',
                    y: 8
                }]
            }],
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                    }
                }
            }
    };
  },
  watch: {
    currentLeaderboardsFilters() {},
    $route: {
      immediate: true,
      async handler() {
        this.$store.commit(SET_LEADERBOARDS_LOADING, true);
        await this.$store.dispatch(GET_APP_TYPE, this.$route.params.slug);
        // await this.$store.dispatch(GET_LEADERBOARDS_FILTERS, {
        //   id: this.currentAppType.id,
        // });

        this.defaultRankType = this.currentAppType.default_leaderboard;

        this.$store.commit(RESET_LEADERBOARDS);
        const qsType = this.$route.params?.type;
        const qsTypeId = this.$route.params?.type_id;
        const qsTypeName =
          qsType === "club"
            ? this.$route.params?.name
            : this.$route.query[qsType];
        const qsTypeGroup = this.$route.query?.group;

        if (qsType || qsTypeId || qsTypeName) {
          this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER, {
            type: qsType,
            id: qsTypeId,
            name: qsTypeName,
          });
        } else {
          this.applyFiltersFromLink();
        }

        this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER_GROUP, qsTypeGroup);
        this.getData();
        this.streamLeaderboardData();
      },
    },
  },
  computed: {
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentLeaderboards() {
      return this.$store.getters.currentNewLeaderboards;
    },
    currentLeaderboardsList() {
      return this.$store.getters.currentNewLeaderboardsList;
    },
    currentLeaderboardsOverview() {
      return this.$store.getters.currentNewLeaderboardsOverview;
    },
    currentLeaderboardsFilters() {
      // Mengambil data dari store
      const myclubs =
        this.$store.getters.currentNewLeaderboardsFilters.club || [];
      const officialclubs =
        this.$store.getters.currentNewLeaderboardsFilters["official club"] ||
        [];

      // Menggabungkan kedua array
      const combinedClubs = [...myclubs, ...officialclubs];

      // Menghapus duplikat berdasarkan ID, prioritas untuk data di `myclubs`
      const uniqueClubs = combinedClubs.reduce((acc, club) => {
        if (!acc[club.id]) {
          acc[club.id] = club;
        }
        return acc;
      }, {});

      // Pisahkan klub berdasarkan kondisi
      const competitiveJoined = [];
      const competitiveNotJoined = [];
      const nonCompetitiveJoined = [];

      Object.values(uniqueClubs).forEach((club) => {
        if (
          club.is_competition &&
          officialclubs.some((official) => official.id === club.id)
        ) {
          competitiveJoined.push(club);
        } else if (
          typeof club.is_competition === "undefined" &&
          typeof club.is_joined === "undefined"
        ) {
          competitiveNotJoined.push(club);
        } else {
          nonCompetitiveJoined.push(club);
        }
      });

      // Gabungkan array sesuai urutan prioritas
      const sortedClubs = [
        ...competitiveJoined,
        ...competitiveNotJoined,
        ...nonCompetitiveJoined,
      ];

      return sortedClubs;
    },
    currentLeaderboardsActiveFilters() {
      return this.$store.getters.currentNewLeaderboardsActiveFilters;
    },
    currentLeaderboardsActiveGroup() {
      return this.$store.getters.currentNewLeaderboardsActiveGroup;
    },
    currentTestMetrics() {
      return this.$store.getters.currentAppMetrics;
    },
    currentNewUserRank() {
      return this.$store.getters.currentNewUserRank;
    },
    currentMetricsLabelNames() {
      return this.defaultMetricsLabel >= 0
        ? this.$store.getters.currentAppMetrics[
            this.defaultMetricsLabel
          ].name.split(",")
        : [];
    },
    isLoadingLeaderboard() {
      return this.$store.getters.currentNewLeaderboardsLoading;
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async applyFiltersFromLink() {
      const filterType = Object.keys(this.$route.query).find(
        (key) => key !== "group"
      );
      let filterName = this.$route.query[filterType];
      let filterId = null;
      if (filterType && filterType === "province") {
        filterId = this.currentLeaderboardsFilters[filterType]?.id;
      } else if (filterType && filterType === "club") {
        let detailClub = await this.$store.dispatch(GET_CLUB_DETAIL, {
          slug: this.$route.query.club,
        });
        filterName = detailClub.data.name;
        filterId = detailClub.data.id;
      } else {
        let filterDatas = this.currentLeaderboardsFilters[`${filterType}s`];
        let selectedFilterData = filterDatas
          ? filterDatas.find((filter) => filter.name === filterName)
          : null;

        if (selectedFilterData) {
          filterId =
            filterType === "hashtag"
              ? selectedFilterData.slug
              : selectedFilterData.id;
        }
      }

      this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER, {
        type: filterType ? filterType : "main",
        id: !filterId ? filterName : filterId,
        name: filterName,
      });
    },
    formatLastDayDate(date) {
      return date
        ? moment(date).tz(this.defaultTimezone).format("DD MMMM YYYY")
        : "";
    },
    changeTableHeaderTitle(time) {
      switch (time) {
        case "yearly":
          return "This Year";
        case "monthly":
          return "This Month";
        default:
          return "All Time";
      }
    },
    changeLabelMetrics(index, header) {
      this.defaultMetricsLabel = index;
      this.defaultMetricsHeader = header;
    },
    changeRankType(rank_type) {
      this.defaultRankType = rank_type;
      this.getData();
    },
    checkMetricsDataObject(label, data) {
      if (Object.hasOwn(data, "metrics_value")) {
        return (
          Object.hasOwn(data.metrics_value, label) &&
          Object.hasOwn(data.metrics_value[label], this.defaultMetricsHeader)
        );
      } else {
        return false;
      }
    },
    formatMetrics(value, label) {
      switch (label) {
        case "CPU":
          return Math.round(value * 100) / 100 + "%";
        case "Memory":
          return parseFloat(value / 1000000).toFixed(1) + "MB";
        case "Time Duration":
          return parseFloat(value / 1000).toFixed(1) + "s";
        case "Request per Second":
          return Math.round(value) + "";
        default:
          return value + "";
      }
    },
    formatDuration(duration) {
      if (["LOGIC", "PLAYGROUND"].includes(this.currentAppType.type)) {
        const date = new Date(duration);
        const minutes = date.getMinutes()
          ? `${date.getMinutes()} Minutes,`
          : "";

        const seconds = date.getSeconds()
          ? `${date.getSeconds()} Seconds `
          : "-";
        return `${minutes} ${seconds}`;
      } else {
        return Math.round(duration) / 1000 + "ms";
      }
    },
    formatToLocalDate(date) {
      return (
        new Date(date).toLocaleString("id-ID", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: this.defaultTimezone,
        }) + " WIB"
      );
    },
    renderFinishedDate(data) {
      const finishedDate = data.finished_date;
      const point = data.point;
      if (+point === 0) return "-";
      return this.formatToLocalDate(finishedDate);
    },
    formatFirstSuccess(createdAt) {
      const start = new Date(this.currentAppType.start_date).getTime() / 1000;
      const created = new Date(createdAt).getTime() / 1000;

      return this.formatDuration(created - start);
    },
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    nextData() {
      this.$store.dispatch(LOADMORE_LEADERBOARDS, {
        id: this.currentAppType.id,
        rank: this.defaultRankType,
      });
    },
    goBack() {
      window.history.back();
    },
    openBlog() {
      window.open(this.blogUrl, "_blank");
    },
    streamLeaderboardData() {
      // event stream
      if (this.currentAppType.id) {
        const API_URL = config.value("DEVCODE_API_URL");
        const base_url = API_URL.substring(0, API_URL.length - 3);
        const url = `${base_url}events/leaderboard/${this.currentAppType.id}`;
        const events = new EventSource(url);

        events.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          if (parsedData?.updated) {
            this.$store.dispatch(GET_LEADERBOARDS, {
              id: this.currentAppType.id,
              page: 1,
              rank: this.currentAppType.default_leaderboard,
            });
          }
        };
        events.onerror = (error) => {
          console.log("Error streamLeaderboardData: ", error);
        };
      }
    },
    async getData() {
      try {
        this.$store.commit(SET_LEADERBOARDS_LOADING, true);

        if (this.currentAppType.app_name) {
          // set default rank type

          await this.$store.dispatch(
            GET_APP_METRICS,
            this.currentAppType.app_name
          );
        }

        if (!isNaN(this.currentAppType.id)) {
          await this.$store.dispatch(GET_LEADERBOARDS_FILTERS, {
            id: this.currentAppType.id,
          });
          await this.$store.dispatch(GET_LEADERBOARDS_INITIAL, {
            id: this.currentAppType.id,
          });
          await this.$store.dispatch(GET_LEADERBOARDS, {
            id: this.currentAppType.id,
            rank: this.defaultRankType,
          });
          await this.$store.dispatch(GET_LEADERBOARDS_OVERVIEW, {
            id: this.currentAppType.id,
            rank: this.defaultRankType,
          });
        }
        this.$store.commit(SET_LEADERBOARDS_LOADING, false);
      } catch (err) {
        this.$store.commit(SET_LEADERBOARDS_LOADING, false);
        Swal.fire({
          title: "Oops . .",
          text: err.message,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }
    },
    autoLoad() {
      // let me = this;
      // this.refreshInterval = setInterval(function () {
      //   me.getData();
      // }, 3000);
    },
  },
  mounted() {
    this.autoLoad();
  },
  created() {
    this.getData();
    this.streamLeaderboardData();
  },
  beforeDestroy: function () {
    // clear auto refresh interval
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },
};
</script>
<style>
.c-chart {
    width: 100%;
}
</style>