import { render, staticRenderFns } from "./LeaderboardNew.vue?vue&type=template&id=3da6312f"
import script from "./LeaderboardNew.vue?vue&type=script&lang=js"
export * from "./LeaderboardNew.vue?vue&type=script&lang=js"
import style0 from "./LeaderboardNew.vue?vue&type=style&index=0&id=3da6312f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CDivider: require('@chakra-ui/vue').CDivider, CSelect: require('@chakra-ui/vue').CSelect, CFlex: require('@chakra-ui/vue').CFlex, CGrid: require('@chakra-ui/vue').CGrid, CImage: require('@chakra-ui/vue').CImage, CLink: require('@chakra-ui/vue').CLink, CSpinner: require('@chakra-ui/vue').CSpinner})
