var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[_c('CBox',{attrs:{"bg":"white","minH":"100px","borderRadius":"5px","boxShadow":"0px 4px 10px rgba(0, 0, 0, 0.1)"}},[_c('CBox',{attrs:{"py":"20px","mx":"20px","justifyContent":"space-between","display":"flex"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"15px","fontWeight":"600","color":"fontMain"}},[_vm._v(" Filters ")])],1),_c('CDivider',{attrs:{"mt":"0"}}),(_vm.loading)?_c('CBox',{attrs:{"display":"flex","justifyContent":"center"}},[_c('CSpinner')],1):_vm._e(),(_vm.$route.name === 'Leaderboard')?_c('CBox',[_c('CText',{attrs:{"py":"4px","mx":"20px","mb":"2","fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"600","color":"fontMain"}},[_vm._v(" General ")]),_vm._l((_vm.timeFilters),function(filter){return _c('CBox',{key:filter.value,attrs:{"py":"4px","mx":"20px","mb":"2","ml":"36px"}},[(filter.value === 'all')?_c('CText',{staticClass:"pointer",attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":filter.value === _vm.currentLeaderboardsActiveGroup ? 600 : 400,"color":"activeMenu"},nativeOn:{"click":function($event){_vm.$route.params.track
              ? _vm.$router.push({
                  name: 'Track Leaderboard',
                  params: {
                    track: _vm.$route.params.track,
                    id: _vm.id,
                  },
                  query: {
                    group: filter.value,
                  },
                })
              : _vm.$router.push({
                  name: 'Leaderboard',
                  params: {
                    slug: _vm.id,
                  },
                  query: {
                    group: filter.value,
                  },
                })}}},[_vm._v(" "+_vm._s(filter.name)+" ")]):_c('CText',{staticClass:"pointer",attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":filter.value === _vm.currentLeaderboardsActiveGroup ? 600 : 400,"color":"activeMenu"},nativeOn:{"click":function($event){_vm.$route.params.track
              ? _vm.$router.push({
                  name: 'Track Leaderboard',
                  params: {
                    track: _vm.$route.params.track,
                    id: _vm.id,
                    type: _vm.currentLeaderboardsActiveFilters.type,
                    type_id: _vm.currentLeaderboardsActiveFilters.id,
                  },
                  query:
                    _vm.currentLeaderboardsActiveFilters.type === 'main'
                      ? { group: filter.value }
                      : {
                          [`${_vm.currentLeaderboardsActiveFilters.type}`]:
                            _vm.currentLeaderboardsActiveFilters.name,
                          group: filter.value,
                        },
                })
              : _vm.$router.push({
                  name: 'Leaderboard',
                  params: {
                    slug: _vm.id,
                    type: _vm.currentLeaderboardsActiveFilters.type,
                    type_id: _vm.currentLeaderboardsActiveFilters.id,
                  },
                  query:
                    _vm.currentLeaderboardsActiveFilters.type === 'main'
                      ? { group: filter.value }
                      : {
                          [`${_vm.currentLeaderboardsActiveFilters.type}`]:
                            _vm.currentLeaderboardsActiveFilters.name,
                          group: filter.value,
                        },
                })}}},[_vm._v(" "+_vm._s(_vm.truncateText(filter.name, 20))+" ")])],1)}),_c('CDivider')],2):_vm._e(),_vm._l((_vm.filterLabels),function(label,i){return _c('CBox',{key:i},[(
          _vm.displayedFilters[label.toLowerCase()] &&
          _vm.displayedFilters[label.toLowerCase()].length !== 0
        )?_c('CText',{attrs:{"py":"4px","mx":"20px","mb":"2","fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"600","color":"fontMain","text-transform":"capitalize"}},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e(),(label === 'Province')?_c('CText',{staticClass:"pointer",attrs:{"py":"4px","ml":"36px","mb":"2","fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":_vm.displayedFilters[label.toLowerCase()].name ===
          _vm.currentLeaderboardsActiveFilters.name
            ? 600
            : 400,"color":"activeMenu"},nativeOn:{"click":function($event){_vm.$route.params.track
            ? _vm.$router.push({
                name: 'Track Leaderboard',
                params: {
                  track: _vm.$route.params.track,
                  id: _vm.id,
                  type: _vm.setFilterType(label.toLowerCase()),
                  type_id: _vm.displayedFilters[label.toLowerCase()].id,
                },
                query: {
                  [`${_vm.setFilterType(label.toLowerCase())}`]:
                    _vm.displayedFilters[label.toLowerCase()].name,
                  group: _vm.currentLeaderboardsActiveGroup,
                },
              })
            : _vm.$router.push({
                name: 'Leaderboard',
                params: {
                  slug: _vm.id,
                  type: _vm.setFilterType(label.toLowerCase()),
                  type_id: _vm.displayedFilters[label.toLowerCase()].id,
                },
                query: {
                  [`${_vm.setFilterType(label.toLowerCase())}`]:
                    _vm.displayedFilters[label.toLowerCase()].name,
                  group: _vm.currentLeaderboardsActiveGroup,
                },
              })}}},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.displayedFilters[label.toLowerCase()].name, 20))+" ")]):_vm._e(),_vm._l((_vm.displayedFilters[label.toLowerCase()]),function(filter,idx){return _c('CText',{key:idx,staticClass:"pointer",attrs:{"py":"4px","ml":"36px","mb":"2","fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":filter.slug === _vm.currentLeaderboardsActiveFilters.id ||
          filter.id === _vm.currentLeaderboardsActiveFilters.id
            ? 600
            : 400,"color":"activeMenu"},nativeOn:{"click":function($event){_vm.$route.params.track
            ? _vm.$router.push({
                name: 'Track Leaderboard',
                params: {
                  track: _vm.$route.params.track,
                  slug: _vm.id,
                  type: _vm.setFilterType(label.toLowerCase()),
                  type_id:
                    label === 'Hashtags' ||
                    label === 'Arena' ||
                    label === 'Language'
                      ? filter.slug
                      : filter.id,
                  name: filter.name,
                },
                query: {
                  [`${_vm.setFilterType(label.toLowerCase())}`]: filter.slug,
                  group: _vm.currentLeaderboardsActiveGroup,
                },
              })
            : _vm.$router.push({
                name: 'Leaderboard',
                params: {
                  slug: _vm.id,
                  type: _vm.setFilterType(label.toLowerCase()),
                  type_id:
                    label === 'Hashtags' ||
                    label === 'Arena' ||
                    label === 'Language'
                      ? filter.slug
                      : filter.id,
                  name: filter.name,
                },
                query: {
                  [`${_vm.setFilterType(label.toLowerCase())}`]: filter.slug,
                  group: _vm.currentLeaderboardsActiveGroup,
                },
              })}}},[_vm._v(" "+_vm._s(_vm.truncateText(filter.name, 20))+" ")])}),(
          _vm.displayedFilters[label.toLowerCase()] &&
          _vm.displayedFilters[label.toLowerCase()].length !== 0
        )?_c('CDivider'):_vm._e()],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }